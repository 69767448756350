
export function getUser() {
  try {
    let user = localStorage.getItem("user");
    if (!user) {
      return false
    } else {
      return user
    }
  } catch (error) {
    // console.log("error", error)
  }
}
