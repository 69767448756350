import React from "react";
import {
	Box,
	Container,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";

const Footer = () => {
	return (
		<Box sx={{ borderTop: '1px solid #e3e3e3', }}>
			<h5 style={{
				color: "green",
				textAlign: "center",
				marginTop: "-20px",
				padding: '20px'
			}}>
				Copyright ©2021 Clover Carte - All Rights Reserved.
			</h5>


			<Container>
				<Row>
					<Column>
						<Heading>Company </Heading>
						<FooterLink href="/about-us" target="_blank">About Us</FooterLink>
						<FooterLink href="/contact-us" target="_blank">Contact Us</FooterLink>
					</Column>
					<Column>
						<Heading>Site Policies </Heading>
						<FooterLink href="/terms" target="_blank">Terms & Conditions  </FooterLink>
						<FooterLink href="/privacy-policy" target="_blank">Privacy Policy</FooterLink>
						<FooterLink href="/refund-policy" target="_blank"> Refund & Cancellation Policy </FooterLink>
						<FooterLink href="/shiping-policy" target="_blank">Shiping Policy </FooterLink>

					</Column>

				</Row>
			</Container>


		</Box>
	);
};
export default Footer;
