export const siteRefundCancellation= {
  title: 'Refund and Cancellation Policy',
  date: '06/08/2022',
  content: [
    {
      id: '1',
      title: 'Cancellation',
      description:
        "<p>As a general rule you shall not be entitled to cancel your order once you have received confirmation of the same Clover Carte (clovercarte.com)' has the right to cancel order in such case full refund will be done from the website to the payment method used while placing the order for online payments. However, in the unlikely event of an item on your order being unavailable, we will contact you on the phone number provided to us at the time of placing the order and inform you of such unavailability. In such an event you will be entitled to cancel the entire order and shall be entitled to a refund in accordance with our refund policy.</p>",
    },
    
    {
      id: '2',
      title: 'Refunds',
      description:
      "<p> You shall be entitled to a refund only if you did not receive your order at the time of placing your order on the Platform All refund amounts shall be credited to your account within 5-7 business days</p>"
    },

  ],
};
