import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { keyframes } from 'styled-components';

const scaling = keyframes`
 0%, 100%{
    transform: scale(0.2);
    background-color: #07090d;
  }
  40%{
    transform: scale(1);
    background-color: #dca835;

  }
  50%{
    transform: scale(1);
    background-color: #00ab54;
  }
`;
const Dot = styled.div`
    height: 20px;
    width: 20px;
    border-radius:50%;
    transform: scale(0);
    background-color:red;
    animation: ${scaling} 2.5s ease-in-out infinite;
    display: inline-block;
    margin:.5rem;
   nth-child(0){
  animation-delay:0s;
}
&:nth-child(1){
  animation-delay:0.2s;
}
&:nth-child(2){
  animation-delay:0.4s;
}
&:nth-child(3){
  animation-delay:0.6s;
}
&:nth-child(4){
  animation-delay:0.8s;
}
&:nth-child(5){
  animation-delay:1s;
}
`;

export default Dot;
