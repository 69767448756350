import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { signupGoogle } from '../../store/slices/user.slice';

// ----------------------------------------------------------------------

export default function SignUpWithGoogle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleGoogleLoginSuccess(tokenResponse) {

    const accessToken = tokenResponse.access_token;

    dispatch(signupGoogle(accessToken, navigate))
  }

  const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button onClick={() => login()} fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon={googleFill} color="#DF3E30" height={24} />
        </Button>
      </Stack>
    </>
  );
}
