import DialogContentText from "@mui/material/DialogContentText";
import { Box, FormControl, OutlinedInput, InputAdornment, InputLabel, Typography, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WALLET_RECOMMENDATION_OPTIONS } from "../../constants/content.constant";
import { useState, useEffect } from "react";
import { WalletPaymentMethod } from "../../components/paymentAction/WalletPaymentAction";
import useModal from "../../hooks/useModal";
import { fDateTimeSuffix } from "../../utils/formatTime";
export const useStyles = makeStyles({
  inputSize: {
    "& input": {
      width: '2.5em !important',
      height: '2.5em',
      margin: '8px 4px',
      background: '#f6f5fa',
      border: 'none',
      borderRadius: '8px'
    }
  }
})


export default function HelpAndReport(props) {
  const order = props.order
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isModalOpen, showModal, hideModal } = useModal();

  const classes = useStyles();
  function handleRecommendedClick(amount) {
    setAmount(amount);
    console.log(amount)
  }
  function handleChangeAmount(e) {
    setAmount(e.target.value);
    console.log(amount)
  }

  const proceedBtnClick = () => {
    hideModal()
    WalletPaymentMethod(amount)
  }

  useEffect(() => {
    if (amount >= 0) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  return (
    <DialogContentText align="center">

      <Stack direction="column" spacing={3}>
        <Box
          component="span"
          display="block"
          sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
        >
          Call us at +91 88271-11809

        </Box>
        <Box>
          if you have any trouble for this order
        </Box>
        <Typography variant="body2" color="text.secondary">
          Total <br></br>
          <b> ₹</b>  {order?.totalAmount}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ORDER PLACED  <br></br>
          {fDateTimeSuffix(order?.date)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ORDER NUMBER  <br></br>
          {order?.orderNumber}
        </Typography>
      </Stack>


      <a
        style={{ textDecoration: "none" }}
        href="tel:+91 88271-11809" className="btn btn-success"
      >
        <Button
          size="large"
          sx={{ mt: 3 }}
          variant="contained"
          fullWidth={true}
          disabled={isLoading}
        >
          Call Now
        </Button>
      </a>

    </DialogContentText>
  );
}
