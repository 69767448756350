// routes
import NewRoutes from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import { BrowserRouter as Router } from 'react-router-dom';

// components
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer/Footer";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <Router>
        <ScrollToTop />
        <GlobalStyles />
        <NewRoutes />
      <Footer></Footer>
      </Router>
    </ThemeConfig>
  );
}
