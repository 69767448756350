import React from 'react';
import styled from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Page from '../components/Page';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;


const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;



export default function ContactUs() {
  return (
    <>
      <Page title="Clover Carte | Contact Us">
        <Modal>
          <HelpPageWrapper>
            <HelpPageContainer>
              <Heading> Contact Us</Heading>
              <br></br>

              <p>27 M.I.D.C,</p>
              <p>Bhagimahari Savner Nagpur </p>

              <p>clovercarte.com</p>
              <p>info@clovercarte.com</p>

              <p>Hotline: (+91) 883-915-3737</p>
              <p>Phone No: (+91) 636-060-4313</p>

            </HelpPageContainer>

          </HelpPageWrapper>
        </Modal>
      </Page>
    </>
  );
}
