import React from 'react';
import { CounterBox, CounterButton, CounterValue } from './counter.style';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


export const Counter = ({
  onDecrement,
  onIncrement,
  value,
  variant,
  className,
}) => {
  return (
    <CounterBox variant={variant} className={className}>
      <CounterButton onClick={onDecrement} variant={variant}>
        <RemoveCircleOutlineIcon sx={{ color: 'green', fontSize: '15px' }} />
      </CounterButton>
      <CounterValue>{value}</CounterValue>
      <CounterButton onClick={onIncrement} variant={variant}>
        <AddCircleOutlineIcon sx={{ color: 'green', fontSize: '15px' }} />
      </CounterButton>
    </CounterBox>
  );
};
