import styled from 'styled-components';

export const Box = styled.div`
padding: 60px 0px;
bottom: 0;
width: 100%;
position: relative;
bottom: 0;


@media (max-width: full) {
	padding: 70px 30px;
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
	
	
`

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: center;

`;

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill,
						minmax(285px, 1fr));
grid-gap: 40px;

@media (max-width: 1000px) {
	grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
}
`;

export const FooterLink = styled.a`

margin-bottom: 10px;
font-size: 12px;
text-decoration: none;

&:hover {
	color: green;
	transition: 200ms ease-in;
}
`;

export const Heading = styled.p`
font-size: 18px;

margin-bottom: 20px;
font-weight: bold;
`;
