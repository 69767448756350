// import { ROUTES_URL } from "./url.constant";

export const OPTIONS_ROLES = [
  { value: "1", label: "CFO/CEO" },
  { value: "2", label: "Purchasing" },
  { value: "3", label: "Sales" },
  { value: "4", label: "Accounting" },
  { value: "5", label: "Other" },
];

export const WALLET_RECOMMENDATION_OPTIONS = [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 300, label: "300" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
];

export const RATING_QUESTIONS = [
  { type: "radio", question: "How was the taste ?", subTitle: "Your feedback will help us improve our product. Feel free to share your experience.", options: [{ value: 0, label: "Bad" }, { value: 1, label: "Good" }, { value: 2, label: " Very Good" }, { value: 3, label: "Awesome" }] },
  { type: "radio", question: "Would you like to recommend this product to your buddies?", subTitle: "Share this order with your loved ones and let them also enjoy the tase .", options: [{ value: 0, label: "Yes" }, { value: 1, label: "No" },] },
  { type: "rating", question: "How much would you like to rate this order overall ?", subTitle: "Your feedback is  valuable, we will be happy to know that you got your food fresh and yum." },
];

export const MESSAGE = {
  LOGIN_SUCCESS: "Login Successfully",
  LOGIN_FAILED: "Login Failed",
  REGISTER_SUCCESS: "Register Successfull. Please login here",
  REGISTER_FAILED: "Register Failed",
  LOGOUT_SUCCESS: "Logout successfully",
  LOOGUT_FAILED: "Try again later !!",
  MAX_ITEM_LIMIT_ALERT: "Maximum order limited to three at a time",
  REVIEW_POST_SUCCESS: "Review added successfully "
};

export const API_STATUS_CODE = {
  NOT_FOUND: 404,
  SUCCESS: 200,
  INTERNAL_SERVER: 500,
  UPDATE: 204,
  EXIST: 406,
  CREATED: 201,
  UNAUTHORIZED: 401,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
};

export const CURRENCY = {
  SIGN: "Rs."

};
