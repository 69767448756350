export const siteShipingPolicy= {
  title: 'Shiping Policy',
  date: '06/08/2022',
  content: [
   
    {
      id: '1',
      title: 'Shiping',
      description:
      "<p>Generally, you will receive your order within 1 – 10 minute.</p> <p> Clover Carte is not responsible for not picking up the goods within 10 minutes after from the delivery platform Your order will be garbage. Clover Carte Hops will endeavor to give the items in protected packaging, however we do not guarantee that the item will arrive in its original condition. If for any reason, your item is damaged, please contact us immediately, phone us Monday – Sunday 8 AM – 7 PM IST. You can also email us at info@clovercarte.com , please provide us with your invoice number.</p>"
    },

  ],
};
