
export function checkUser() {
  try {
    let token = localStorage.getItem("token");
    if (!token) {
      return false
    } else {
      return true
    }
  } catch (error) {
    // console.log("error", error)
  }
}
