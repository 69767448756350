import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
import { Icon } from "@iconify/react";
import userIcon from "@iconify/icons-eva/person-fill";
import settingIcon from "@iconify/icons-eva/settings-fill";
import logOutIcon from "@iconify/icons-eva/log-out-fill";
import editProfileIcon from "@iconify/icons-eva/edit-2-fill";
import { checkUser } from "../../utils/checkUser";
import { getUser } from "../../utils/getUser";

// ----------------------------------------------------------------------

const user = JSON.parse(getUser())
const LOGGED_IN = [
  {
    value: "NAME",
    label: user ? (user.firstName + " " + user.lastName) : "",
  },
  {
    value: "MY_PROFILE",
    label: "My Profile",
    icon: userIcon,
  },
  {
    value: "UPDT_PROFILE",
    label: "Update Profile",
    icon: editProfileIcon,
  },
  {
    value: "SETTING",
    label: "Settings",
    icon: settingIcon,
  },
  {
    value: "LOGOUT",
    label: "Logout",
    icon: logOutIcon,
  },
];

const LOGGED_OUT = [
  {
    value: "SIGN_IN",
    label: "Sign In",
    icon: userIcon,
  }
];

const ACCOUNT_OPTIONS = checkUser() ? LOGGED_IN : LOGGED_OUT

// ----------------------------------------------------------------------

export default function User() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickItem = (value) => {
    if (value === "LOGOUT") {
      console.log("you want to log out ?")
    }

  };

  return (
    <>
      <IconButton
        ref={anchorRef}

        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Icon icon={userIcon} width={25} height={25} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {ACCOUNT_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === ACCOUNT_OPTIONS[0].value}
              onClick={() => handleClickItem(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                {/* <Box component="img" alt={option.label} src={option.icon} /> */}
                <Icon icon={option.icon} width={25} height={25} />

              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
