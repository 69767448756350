import axios from "axios";
// constants
import { API_URL } from "../../constants/url.constant";
import { postMethod } from "../../utils/api";
import { getLocalData, getSessionData } from "../../utils/helper";
import toastr from "toastr";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const WalletPaymentMethod = async (amount) => {
  const user = JSON.parse(getLocalData("user"))
  const name = user ? user.firstName : ""
  const email = user ? user.email : ""
  const contact = user ? user.contact : ""
  const customer_id = user ? user.customer_id : ""
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  axios.post(API_URL.RECHARGE_WALLET, { totalAmt: amount }).then((info) => {
    var options = {
      key: '',// process.env.RAZORPAY_ID,
      key_secret: '',// process.env.RAZORPAY_SECRET, // Enter the Key ID generated from the Dashboard

      name: "Clover Carte",
      description: "Clover Carte Wallet recharge",
      image: "https://clovercarte.in/logo2.png",
      order_id: info.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // callback_url: '/order-received', // API_URL.ORDER_COMPLETE,
      handler: async function (response) {
        // console.log("rzp-response", response);

        const finalRes = await postMethod(API_URL.WALLET_RECHARGE_COMPLETE, { razorpay_payment_id: response?.razorpay_payment_id, user_email: email }, false, false)
        const id = finalRes?.data?.data
        if (finalRes?.status === 200) {
          window.location.assign('/wallet')
          //navigate to the Wallet page and sho notification of recharge done
        }
      },
      redirect: false,
      remember_customer: true,
      customer_id: customer_id,
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },
      save: 1,
      notes: {
        address: "Purchase made using Clover Carte",
        // msg:items?.map(i=>i?.title)?.toString(),
        msg: "Clover Carte Wallet Recharge"
      },
      theme: {
        color: "#000000",
      },
    };
    // console.log("info", info);
    const paymentObject = new (window).Razorpay(options);
    paymentObject.open();
  });
};
