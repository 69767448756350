import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
import { getMethod } from "../../utils/api";
import { API_URL } from "../../constants/url.constant";
import { API_STATUS_CODE } from "../../constants/content.constant";
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from "../../constants/url.constant";


// ----------------------------------------------------------------------

export default function GuestLogin() {
  const navigate = useNavigate();
  const fetchGuestSession = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_GUEST_SESSION, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        navigate(ROUTES_URL?.HOME, { replace: true });
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const createGuestUser = () => {
    fetchGuestSession()

  }



  return (
    <>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          OR
        </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
        <Button onClick={() => createGuestUser()} fullWidth size="large" color="inherit" variant="outlined">
          {/* <Icon icon={googleFill} color="#DF3E30" height={24} /> */} Continue as Guest
        </Button>
      </Stack>


    </>
  );
}
