import { useEffect, useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import { Button, Box, Avatar, IconButton, Typography } from "@mui/material";
//Icon
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// components
import MenuPopover from "../../components/MenuPopover";
import { CartItem } from "../../components/cart-item/cart-item";
//assets
import { NoCartBag } from "../../assets/icons/NoCartBag";
//CSS
import { NoProductImg, ItemWrapper, } from "./cart.style";
//Payments Import
import { PaymentMethod } from "../../components/paymentAction/PaymentAction";
//Context or state or Store
import { useCart } from "../../contexts/cart/use-cart";
import toastr from "toastr";
import { MESSAGE } from "../../constants/content.constant";
import CheckoutBtnBottom from "../../features/cart/CheckoutBtnBottom";
import CheckoutBtn from "../../features/cart/CheckoutBtn";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [checkoutBtnFlag, setCheckoutBtnFlag] = useState(false);

  // const itemsfake = [
  //   { title: 'Black Tea', qnty: 2, price: 10 },
  //   { title: 'Orange Juice', qnty: 1, price: 40 },
  //   { title: 'Lemon Tea', qnty: 1, price: 30 },
  //   { title: 'Apple Juice', qnty: 1, price: 40 },

  // ]
  const {
    items,
    addItem,
    removeItem,
    removeItemFromCart,
    cartItemsCount,
    calculatePrice,
  } = useCart();

  useEffect(() => {
    setCheckoutBtnFlag((cartItemsCount() > 0) ? false : true)
  }, [cartItemsCount]);

  const MaxItemAlert = () => {
    toastr.success(MESSAGE.MAX_ITEM_LIMIT_ALERT);
    return false

  }

  const payDetails = {
    total_amount: calculatePrice(),
    title: "",
    items: items,
  };

  const handleCheckoutBtnClick = () => {
    PaymentMethod(payDetails);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 50,
          height: 50,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <LocalMallIcon />

        {/* <Avatar sx={{ bgcolor: 'green' }}  >
          <LocalMallIcon />
        </Avatar> */}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 450 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h5" noWrap>
            Cart
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }} noWrap>
            Total Items :{items.length}
          </Typography>
        </Box>

        <ItemWrapper className="items-wrapper">
          {!!cartItemsCount() ? (
            items.map((item, i) => (
              <CartItem
                key={`cartItem-${item._id + i}`}
                onIncrement={() => (cartItemsCount() === 3) ? MaxItemAlert() : (addItem(item))}
                onDecrement={() => removeItem(item)}
                onRemove={() => removeItemFromCart(item)}
                data={item}
              />
            ))
          ) : (
            <>
              <NoProductImg>
                <NoCartBag />
              </NoProductImg>
            </>
          )}
        </ItemWrapper>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Typography variant="h5" noWrap>
            Total :  {calculatePrice()} <CurrencyRupeeIcon sx={{ fontSize: '15px' }} />
          </Typography>
        </Box>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <CheckoutBtn onClick={handleClose} />
          {/* <Button onClick={handleCheckoutBtnClick} disabled={checkoutBtnFlag} fullWidth color="primary" variant="outlined">
            Check Out
          </Button> */}
        </Box>
      </MenuPopover>
    </>
  );
}
