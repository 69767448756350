import DialogContentText from "@mui/material/DialogContentText";
import { Box, FormControl, OutlinedInput, InputAdornment, InputLabel, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WALLET_RECOMMENDATION_OPTIONS } from "../../constants/content.constant";
import { useState, useEffect } from "react";
import { WalletPaymentMethod } from "../../components/paymentAction/WalletPaymentAction";
import useModal from "../../hooks/useModal";
export const useStyles = makeStyles({
  inputSize: {
    "& input": {
      width: '2.5em !important',
      height: '2.5em',
      margin: '8px 4px',
      background: '#f6f5fa',
      border: 'none',
      borderRadius: '8px'
    }
  }
})


export default function RechargeWallet(callBackHideModal) {
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isModalOpen, showModal, hideModal } = useModal();

  const classes = useStyles();
  function handleRecommendedClick(amount) {
    setAmount(amount);
    console.log(amount)
  }
  function handleChangeAmount(e) {
    setAmount(e.target.value);
    console.log(amount)
  }

  const proceedBtnClick = () => {
    hideModal()
    WalletPaymentMethod(amount)
  }

  useEffect(() => {
    if (amount >= 0) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  return (
    <DialogContentText align="center">
      <Box
        component="span"
        display="block"
        sx={{ fontSize: "16px", fontWeight: "600", m: 0.5 }}
      >
        Please Enter Amount
      </Box>

      <Box>
        <FormControl fullWidth sx={{ m: 1 }}>

          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
            label="Amount"
            value={amount}
            onChange={handleChangeAmount}
            type="number"
          />
        </FormControl>
      </Box>

      <Box sx={{ mt: 3, p: 1 }}>
        <Typography component="body2" sx={{ color: "text.secondary" }} >
          Recommended
        </Typography>
      </Box>

      <Box>
        {WALLET_RECOMMENDATION_OPTIONS &&
          WALLET_RECOMMENDATION_OPTIONS?.map((recom) => {
            return <Button
              onClick={() => handleRecommendedClick(recom?.value)}
              size="small"
              color="inherit"
              variant="outlined"
              sx={{ mr: "2px", mb: "2px" }}>
              {recom?.label}
            </Button>
          })
        }

      </Box>

      <Button
        // to="/"
        size="large"
        sx={{ mt: 3 }}
        variant="contained"
        fullWidth={true}
        onClick={proceedBtnClick}
        disabled={isLoading}
      >
        PROCEED
      </Button>
    </DialogContentText>
  );
}
