import { useEffect, useState } from "react";
// library
import {
    Grid,
    Container,
    Typography,
    Card,
    CardHeader,
    Fab,
    Button,
    Icon,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
// utils
import { getMethod } from "../utils/api";
// constants
import { API_URL } from "../constants/url.constant";
import { API_STATUS_CODE, OPTIONS_ROLES } from "../constants/content.constant";
import OrderReceivedWrapper, { OrderReceivedContainer } from "../features/order/order/order-received.style";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ROUTES_URL } from "../constants/url.constant";
import { getUser } from "../utils/getUser.js";
import useModal from "../hooks/useModal.js";
import Modal from "../components/Modal/Modal.js";
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import SouthWestRoundedIcon from '@mui/icons-material/SouthWestRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// ----------------------------------------------------------------------
import { WalletIcon } from '@mui/icons-material/Wallet';
import RechargeWallet from "../features/wallet/RechargeWallet.js";

export default function MyWallet() {
    const [walletData, setWalletData] = useState(0);
    const [walletTransactions, setWalletTransactions] = useState(null);
    const user = JSON.parse(getUser())
    const { isModalOpen, showModal, hideModal } = useModal();
    const [expanded, setExpanded] = useState(false);

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };
    const fetchWallet = async () => {
        try {
            // setLoading(true);
            const response = await getMethod(API_URL.GET_WALLET + '/' + user?.email, false, false);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setWalletData(response?.data?.data);
            }
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    const fetchWalletTransaction = async () => {
        try {
            // setLoading(true);
            const response = await getMethod(API_URL.GET_WALLET_TRANSACTIONS + '/' + user?.email, false, false);
            if (response?.status === API_STATUS_CODE.SUCCESS) {
                setWalletTransactions(response?.data?.data);
            }
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    function handleRechargeClick() {
        showModal();
    }

    useEffect(() => {
        try {
            fetchWallet();
            fetchWalletTransaction();
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeModal={hideModal}
                content={<RechargeWallet />}
            />
            <Outlet />
            <Page title="Clover Carte | Wallet ">
                <OrderReceivedWrapper>
                    <OrderReceivedContainer>
                        <Link to={ROUTES_URL.HOME}>
                            <p className="home-btn">
                                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
                            </p>
                        </Link>
                        <Container maxWidth="xl" sx={{ mt: 5 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Card>
                                        <Grid container direction="column">
                                            <Grid item md={12} sm={12} xs={12}>
                                                <CardHeader title="CC Wallet Balance" pt={2} />
                                                <Typography variant="h5" sx={{ p: 2, pl: 3 }}>
                                                    ₹ {walletData?.balance || 0}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12} sx={{ p: 2, pl: 3 }}>
                                                <Button onClick={() => handleRechargeClick()} fullWidth size="large" color="inherit" variant="outlined">
                                                    {/* <Icon icon={WalletIcon} color="#DF3E30" height={24} />  */} Add Money to Wallet
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Card>
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                    {walletTransactions?.length}
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary' }}>Wallet Transactions</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container direction="column" sx={{ border: '1px solid #eee' }}>
                                                    {walletTransactions &&
                                                        walletTransactions?.map((data, index) => {
                                                            return <>
                                                                <Grid item container direction="row" sx={{ p: 1, }}>
                                                                    <Grid item container direction="row">
                                                                        <Grid item sx={{}} xs={2}> {(data?.trans_type === "CREDIT") ? <Fab size="small" color="primary"> < SouthWestRoundedIcon /></Fab> : <Fab size="small" color="secondary"> <NorthEastRoundedIcon /></Fab>} </Grid>
                                                                        <Grid item container direction="row" sx={{}} xs={10} justifyContent="flex-start">
                                                                            <Grid item xs={8} sx={{}}>
                                                                                {(data?.trans_type === "CREDIT") ? <span>
                                                                                    Added to
                                                                                    <br></br>
                                                                                    Wallet
                                                                                </span> :
                                                                                    <span>
                                                                                        Paid to
                                                                                        <br></br>
                                                                                        Clover Carte
                                                                                    </span>}
                                                                            </Grid>
                                                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>{(data?.trans_type === "CREDIT") ? "+" : "-"} Rs. {data?.amount / 100}</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item container sx={{ pt: 1 }}>
                                                                        <Grid item xs={2}> </Grid>
                                                                        <Grid item xs={4}> <span > {(new Date(data?.updatedAt)).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} </span></Grid>
                                                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}> {data?.trans_type}</Grid>

                                                                    </Grid >
                                                                </Grid>
                                                                <Divider />
                                                            </>
                                                        })}

                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>


                                    </Card>
                                </Grid>

                            </Grid>
                        </Container>
                    </OrderReceivedContainer>
                </OrderReceivedWrapper>

            </Page>
        </>
    );
}
